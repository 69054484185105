import { Suspense, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import routePaths from "@/constants/routePaths";
import { useDispatch, useSelector } from "react-redux";
import { myLookup } from "./redux/user/userActions";
import socketActions from "./redux/socket/socketActions";
import SideBar from "./components/SideBar";
import socketConnect from "./socket/socketEntry";
import cookies from "./helpers/cookies";
import cookiesContants from "./constants/cookiesContants";
import ChatLists from "./components/ChatLists";
import Header from "./components/Header";

const App = () => {
  const checkSession = cookies.get(cookiesContants.sessionId);
  if (!checkSession) {
    window.location = process.env.LANDING_URL;
  }
  const uid = useSelector((state) => state.user?.uid);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(myLookup());
  }, [dispatch]);

  useEffect(() => {
    if (uid?.length > 0) {
      const mySocket = socketConnect(uid);
      (async () => {
        for await (let data of mySocket) {
          dispatch(socketActions(data));
        }
      })();
    }
  }, [uid]);

  return (
    <>
      <BrowserRouter>
        <Header />
        <ChatLists />
        <Suspense fallback={"Loading..."}>
          <Routes>
            {routePaths.map((props, index) => (
              <Route key={`route_${index}`} {...props} />
            ))}
          </Routes>
          <SideBar />
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
