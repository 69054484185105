import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { calendarTime } from "../../helpers/calendarTime";
import ProfileImage from "../ProfileImage";

function ContactCard({ im, uid, isActive }) {
  const userStatus = useSelector((state) => state.us[im.uid]);
  return (
    <div className={classNames("ld-chat-card", { active: isActive })}>
      <ProfileImage user={{ userName: im?.user }} />
      <div className="ld-card-content">
        <div className="ld-card-name">
          {userStatus?.isActive === 1 && (
            <div
              style={{
                width: "10px",
                height: "10px",
                display: "inline-block",
                borderRadius: "50rem",
                background: "green",
                marginRight: "5px",
                transition: "2s ease",
              }}
            />
          )}
          {im?.user}
          <div className="ld-card-time">{calendarTime(im.lastMessage?.sentAt)}</div>
        </div>
        {im?.lastMessage?.metaData && (
          <div className="ld-card-message">
            {im.lastMessage?.sender === uid && (
              <i
                className={classNames({
                  "ri-check-double-line ld-primary-color": im.lastMessage?.isRead,
                  "ri-check-line": !im.lastMessage?.isRead,
                })}
              ></i>
            )}
            {im?.lastMessage?.metaData?.txt || im?.lastMessage?.metaData?.fileName}
            {im.unReadCount > 0 && <div className="ld-card-msg-count">{im?.unReadCount}</div>}
          </div>
        )}
      </div>
    </div>
  );
}
ContactCard.defaultProps = {
  im: {
    user: "NaN",
    lastMessage: {
      sentAt: new Date(),
      sender: "",
      metaData: {},
      isRead: false,
      unReadCount: 0,
    },
  },
  isActive: false,
  uid: "",
};

export default ContactCard;
