// export const debounce = (func, delay) => {
//   let debounceTimer;
//   return function () {
//     console.log(delay);
//     const context = this;
//     const args = arguments;
//     clearTimeout(debounceTimer);
//     debounceTimer = setTimeout(() => func.apply(context, args), delay);
//   };
// };
let timer;
export function debounce(func, delay) {
  if (timer) clearTimeout(timer);
  timer = setTimeout(func, delay);
}

let throttlePause;
export const throttle = (callback, time) => {
  if (throttlePause) return;
  throttlePause = true;
  setTimeout(() => {
    callback();
    throttlePause = false;
  }, time);
};
