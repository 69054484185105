import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import snackbar from "../../helpers/snackbar";
import { fetchGroups } from "../../redux/channels/channelsActions";
import { fetchChats } from "../../redux/im/imActions";
// import { getOnlineUsers } from "../../redux/us/usActions";
import ContactCard from "./ContactCard";

function ChatLists() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const chid = useSelector((state) => state.msg.cch);
  const [status, setStatus] = useState("loading");
  const ims = useSelector((state) => state.im);
  const channels = useSelector((state) => state.channels);
  const uid = useSelector((state) => state.user?.["uid"]);

  useEffect(() => {
    dispatch(
      fetchChats(
        () => {
          setStatus("success");
          // if (chats.length > 0) {
          // const uids = chats.map((eachUser) => eachUser.uid);
          // dispatch(getOnlineUsers(uids));
          // }
        },
        () => setStatus("error")
      )
    );
    dispatch(
      fetchGroups(
        () => {},
        () => snackbar("Unable to fetch groups", "ld-bg-danger")
      )
    );
  }, [dispatch]);
  function createGroup() {
    searchParams.set("sidebar", "cg");
    return navigate({
      search: searchParams.toString(),
    });
  }
  return (
    <section className="ld-chat-list">
      <div className="ld-container">
        <div className="ld-chat-wrapper ld-mb-20">
          <div className="ld-title">
            Group
            <i className="ri-add-line ld-float-right ri-lg" onClick={createGroup}></i>
          </div>
          <div className="ld-group-list">
            <ul>
              {Object.values(channels)?.map((eachCh) => (
                <li
                  className={classNames({ active: chid === eachCh.chid })}
                  key={eachCh.chid}
                  onClick={() => navigate("/group/" + eachCh.chid)}
                >
                  {eachCh.name}
                  {eachCh?.unReadCount > 0 && (
                    <div className="ld-card-msg-count">{eachCh?.unReadCount}</div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="ld-chat-wrapper">
          <div className="ld-title">
            Contacts
            {/* <i className="ri-add-line ld-float-right ri-lg"></i> */}
          </div>
          <div className="ld-contact-list">
            {status === "loading" && "Loading..."}
            {status === "success" && (
              <ul>
                {Object.values(ims).map((eachIm) => (
                  <li key={eachIm.chid} onClick={() => navigate("/chat/" + eachIm.chid)}>
                    <ContactCard im={eachIm} ui={uid} isActive={chid === eachIm.chid} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChatLists;
