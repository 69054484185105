import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(calendar);
export function calendarTime(time) {
  return dayjs(time).calendar(null, {
    sameDay: "h:mm A", // The same day ( 2:30 AM )
    nextDay: "[Tomorrow]", // The next day ( Tomorrow at 2:30 AM )
    nextWeek: "dddd", // The next week ( Sunday at 2:30 AM )
    lastDay: "[Yesterday] h:mm A", // The day before ( Yesterday at 2:30 AM )
    lastWeek: "dddd h:mm A", // Last week ( Last Monday at 2:30 AM )
    sameElse: "DD/MM/YYYY", // Everything else ( 7/10/2011 )
  });
}
export function conversationDate(time) {
  return dayjs(time).calendar(null, {
    sameDay: "[Today]", // The same day ( 2:30 AM )
    lastDay: "[Yesterday]", // The day before ( Yesterday at 2:30 AM )
    lastWeek: "[Last] dddd DD/MM/YYYY", // Last week ( Last Monday at 2:30 AM )
    sameElse: "DD/MM/YYYY", // Everything else ( 7/10/2011 )
  });
}

dayjs.extend(updateLocale);
export function relativeTime(time) {
  return dayjs(time).format("h:mm A");
}

export function dateDiff(date1 = new Date(), date2 = new Date(), unit = "day") {
  return dayjs(date1).diff(date2, unit);
}
