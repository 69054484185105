import apiEndPoints from "../../apiEndPoints";
import snackbar from "../../helpers/snackbar";
import httpReq from "../../services/http";
import imConstant from "./imConstant";

export function createIm({ userId }, onSuccess, onError) {
  return async function action(dispatch) {
    try {
      const response = await httpReq({
        url: apiEndPoints.CREATE_IM,
        method: "post",
        data: { userId },
      });
      if (response?.status === 200) {
        if (response?.data?.status === 200 || response?.data?.status === 201) {
          const { chid, recipient, user } = response.data;
          dispatch({ type: imConstant.ADD_IM, data: { chid, recipient, user } });
          return onSuccess({ chid });
        } else {
          return onError();
        }
      } else return onError();
    } catch (error) {
      return onError();
    }
  };
}

export function fetchChats(onSuccess, onError) {
  return async function action(dispatch) {
    try {
      const response = await httpReq({
        url: apiEndPoints.FETCH_CHANNELS,
        method: "get",
        params: { nocache: new Date().getTime() },
      });
      if (response?.status === 200) {
        if (response?.data?.status === 200 || response?.data?.status === 201) {
          dispatch({ type: imConstant.FETCH_IM, data: response?.data?.chats });
          return onSuccess(response?.data?.chats);
        } else {
          return onError();
        }
      } else return onError();
    } catch (error) {
      return onError();
    }
  };
}

export function markRead({ chid }) {
  return async function action(dispatch) {
    try {
      const response = await httpReq({
        method: "post",
        url: apiEndPoints.MARK_READ,
        data: { chid },
      });
      if (response?.status === 200 && response?.data?.status === 200) {
        return dispatch({ type: imConstant.RESET_UNREAD_COUNT, data: { chid: chid } });
      } else throw "Unable to Mark";
    } catch (err) {
      return snackbar("Unable to mark as read", "ld-bg-warning");
    }
  };
}
