import { create } from "socketcluster-client";
let mySocket;
function socketConnect(uid) {
  let socket = create({
    hostname: "hallchat.prodytics.com/api",
    port: null,
    // hostname: "localhost",
    // path: '/api/socketcluster/',
    // port: 3001,
    autoReconnect: true,
    autoReconnectOptions: { maxDelay: 1000 },
    query: { uid: uid },
  });
  mySocket = socket.subscribe(uid);
  return mySocket;
}
export default socketConnect;
export { mySocket };
