import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import useOutsideClick from "../../tailor-hooks/useOutsideClick";
import { createIm } from "../../redux/im/imActions";
import ProfileImage from "../ProfileImage";
import infographics from "../../constants/infographics";
import { debounce } from "../../helpers/debounce";
import { userLookup } from "../../helpers/userLookup";

/**
 * Header is an Global Component which is used for Search Engine, Quick Menu, Notification Bar, User Menu
 * @component
 * @returns JSX.Element
 */

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state?.user);
  //Used for storing lookup API data
  const [searchList, setSearchList] = useState([]);

  //Ref to identify outside click
  const searchRef = useRef();
  useOutsideClick(searchRef, () => onOutsideClick({ clear: true }));

  const handleChange = useCallback((ev) => {
    if (ev.target.value !== "" && ev.target.value.length > 2)
      return debounce(() => userLookup(ev.target.value, (users) => setSearchList(users)), 400);
    else onOutsideClick({ clear: true });
  }, []);

  //on Out side click Empty array to not show dropdown list
  function onOutsideClick({ clear }) {
    if (clear) return setSearchList([]);
  }

  function handleCreateIm(userId) {
    function onSuccess({ chid }) {
      navigate(`/chat/${chid}`);
      return onOutsideClick({ clear: true });
    }
    function onError(error) {
      console.error(error);
    }
    return dispatch(createIm({ userId }, onSuccess, onError));
  }
  function viewProfile() {
    searchParams.set("sidebar", "profile");
    return navigate({
      search: searchParams.toString(),
    });
  }
  return (
    <header>
      <div className="ld-logo">
        {/* Chat <sup className="ld-black-color">BETA</sup> */}
        <img src={infographics.logo} alt="Hall Chat" />
      </div>
      <div className="ld-search">
        <div className="ld-input-search-post" ref={searchRef}>
          <input
            type="text"
            name="search"
            id="header_search"
            placeholder="Search"
            autoComplete="off"
            onChange={handleChange}
          />
          <i className="ri-search-line ri-1x"></i>
          {searchList.length !== 0 && (
            <div className="ld-search-dropdown">
              <ul>
                {searchList.map((eachSearch) => (
                  <li
                    key={`headerSearch_${eachSearch?._id}`}
                    onClick={() => handleCreateIm(eachSearch?._id)}
                  >
                    <div className="ld-avatar ld-avatar-md">
                      {eachSearch?.userName[0].toUpperCase()}
                    </div>
                    <div className="ld-user-data">
                      <div className="ld-black-color">{eachSearch?.userName}</div>
                      <div className="ld-grey-color">Desginer</div>
                    </div>
                    <div className="ld-user-data">
                      <div className="ld-black-color">{eachSearch?.email}</div>
                      <div className="ld-grey-color">India</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="ld-menu">
        <ul>
          <li>
            <i className="ri-notification-line ri-2x"></i>
          </li>
          <li className="ld-dropdown">
            <i className="ri-apps-2-fill ri-2x"></i>
            {/* <div className="ld-dropdown-list">
              <ul>
                <li>
                  <i className="ri-message-2-line"></i>Chat
                </li>
                <li>
                  <i className="ri-sticky-note-line"></i>Sticky Notes
                </li>
                <li>
                  <i className="ri-edit-2-line"></i>Notes
                </li>
              </ul>
            </div> */}
          </li>
          <li title={user?.userName} onClick={viewProfile}>
            <ProfileImage user={user} size="sm" />
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
